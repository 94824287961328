import store from '@/store'
import { PipelineRun } from '@/types/Types'
import { ApiQueryService } from './ApiQueryService'

export default class PipelinePoller {
  public static poll (interval: number): void {
    // Check if polling is already running
    if (store.state.pipelineStore.isPolling) {
      // console.log('Polling is already running.')
      return
    }

    // Set polling flag
    store.commit('pipelineStore/setIsPolling', true)

    // Retrieve running pipelines
    store.dispatch('pipelineStore/updateRunningPipelines').then(() => {
      // console.log('[Poll] Running pipelines:', store.state.pipelineStore.runningPipelines)
      // Check their status every x (interval) secs until they are all completed
      const pollInterval = setInterval(() => {
        if (store.state.pipelineStore.runningPipelines.length === 0) {
          store.commit('pipelineStore/setIsPolling', false)
          clearInterval(pollInterval)
          return
        }

        // Check status of each running pipeline
        store.state.pipelineStore.runningPipelines.forEach((run: PipelineRun) => {
          if (run.pipeline === 'expression upload') {
            // Check status of upload pipelines with checkUploadStatus router
            ApiQueryService.checkUploadStatus(run.name)
              .then((response) => {
                console.log('[PipelinePoller] Upload Status of ', run.name, ':', response)
                if (response.state === 'SUCCESS' || response.state === 'FAILED') {
                  store.commit('pantoStore/setAlert', {
                    enabled: true,
                    message: 'Upload ' + (response.state === 'SUCCESS' ? 'completed' : 'failed') + '! New data is loaded on page reload or on switching to another graph.',
                    type: response.state === 'SUCCESS' ? 'success' : 'error',
                    dismissible: true
                  })
                  // Remove pipeline from running pipelines
                  // store.commit('pipelineStore/deleteUploadPipeline', run.name)
                }
              }).catch((error: any) => {
                console.error('error in getting status of upload pipe ' + error)
              })
          } else {
            // Status of other pipes can be checked with checkPipelineStatus router
            ApiQueryService.checkPipelineStatus(run.name).then((pipeline: any) => {
              console.log('Pipeline status:', pipeline.name, pipeline.status)
              if (pipeline.status === 'SUCCEEDED' || pipeline.status === 'FAILED') {
                store.commit('pantoStore/setAlert', {
                  enabled: true,
                  message: 'Pipeline ' + pipeline.parameters.panregion + ' ' + (pipeline.status === 'SUCCEEDED' ? 'completed' : 'failed') + '! For further actions see the <a href="/pipelines">status page</a>.',
                  type: pipeline.status === 'SUCCEEDED' ? 'success' : 'error',
                  dismissible: true
                })
                // Remove pipeline from running pipelines
                store.commit('pipelineStore/deleteRunningPipeline', pipeline.name)
              }
            }).catch((error: any) => {
              console.error('error in status of pipeline ' + error)
            })
          }
        })
      }, interval)
    }).catch((error: any) => {
      console.error(error)
      store.commit('pipelineStore/setIsPolling', false)
    })
  }
}
