import store from '@/store'
import { ApiQueryService } from '@/services/ApiQueryService'
import { PipelineStoreState, RootState } from '@/types/StoreTypes'
import { PipelineRun } from '@/types/Types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import Config from '@/graph/Config'

const namespaced = true

const state: PipelineStoreState = {
  runningPipelines: [],
  isPolling: false
}

const mutations: MutationTree<PipelineStoreState> = {
  addRunningPipeline (state, payload: PipelineRun) {
    state.runningPipelines.push(payload)
  },
  deleteRunningPipeline (state, payload: string) {
    const index = state.runningPipelines.findIndex(pipeline => pipeline.name === payload)
    if (index !== -1) {
      state.runningPipelines.splice(index, 1)
    }
  },
  setRunningPipeline (state, payload: Array<PipelineRun>) {
    state.runningPipelines = payload
  },
  setIsPolling (state, payload: boolean) {
    state.isPolling = payload
  }
}

const actions: ActionTree<PipelineStoreState, RootState> = {
  updateRunningPipelines ({ state }) {
    return new Promise<void>((resolve) => {
      state.runningPipelines = []
      ApiQueryService.checkAllPipelineStatus()
        .then((response: PipelineRun[]) => {
          // console.log('Pipelines:', response)
          for (const run of response) {
            run.pipeline = run.pipeline.substring(run.pipeline.lastIndexOf('-') + 1)
            if (run.status === 'ACTIVE') {
              if (!state.runningPipelines.some(pipeline => pipeline.name === run.name)) {
                state.runningPipelines.push(run)
              }
            }
          }
          console.log('  Running pipelines:', state.runningPipelines)

          const promises: any[] = []
          ApiQueryService.getFile(Config.pipelineFolderName + Config.uploadPipelinesFileName)
            .then((response) => {
              const uploadPipelines = response as PipelineRun[]
              console.log('Upload pipelines:', uploadPipelines)
              uploadPipelines.forEach((run: PipelineRun) => {
                promises.push(ApiQueryService.checkUploadStatus(run.name)
                  .then((response) => {
                    console.log('[UpdateRunningPipelines] Upload Status of ', run.name, ':', response)
                    if ((response.status as string).startsWith('Processing')) {
                      if (!state.runningPipelines.some(pipeline => pipeline.name === run.name)) {
                        state.runningPipelines.push(run)
                      }
                    }
                  })
                  .catch((error) => {
                    console.error(error)
                  }))
              })
            })
            .catch(() => {
              console.error('upload pipeline file does not exist')
            })
          console.log('  Running pipelines:', state.runningPipelines)

          Promise.all(promises).then(() => {
            resolve()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }
}

const getters: GetterTree<PipelineStoreState, RootState> = {}

export const PipelineStore: Module<PipelineStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
