import { ApiService } from '@/services/ApiService'
import { UserLogin } from '@/types/Types'
import { AxiosPromise, AxiosResponse } from 'axios'

export const ApiQueryService = {
  login (user: UserLogin): AxiosPromise {
    // Convert to x-www-form-urlencoded
    const userParams = new URLSearchParams()
    userParams.append('username', user.name)
    userParams.append('password', user.password)

    return ApiService({
      url: process.env.VUE_APP_API_VERSION + '/auth/jwt/login',
      data: userParams,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        accept: 'application/json'
      }
    })
  },

  getFile (path: string): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('Getting file:', process.env.VUE_APP_PROJECT_PATH + path)
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/s3/download/' + process.env.VUE_APP_PROJECT_PATH + path,
        method: 'get'
      }).then(response => {
        resolve(response.data) // Process response data
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.warn('Error data:' + error.response.data)
          console.warn('Error status:' + error.response.status)
          console.warn('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.warn('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.warn('Error message:' + error.message)
        }
        console.warn('Error config:' + error.config)
        reject(error)
      })
    })
  },

  getZippedFile (path: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/s3/download/' + process.env.VUE_APP_PROJECT_PATH + path,
        method: 'get',
        responseType: 'arraybuffer'
      }).then(response => {
        const arrayBufferView = new Uint8Array(response.data)
        const blob = new Blob([arrayBufferView], { type: response.headers['content-type'] as string })
        const urlCreator = window.URL || window.webkitURL
        const url = urlCreator.createObjectURL(blob)
        resolve(url) // Process response data
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.warn('Error data:' + error.response.data)
          console.warn('Error status:' + error.response.status)
          console.warn('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.warn('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.warn('Error message:' + error.message)
        }
        console.warn('Error config:' + error.config)
        reject(error)
      })
    })
  },

  getImage (path: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/s3/download/' + process.env.VUE_APP_PROJECT_PATH + path,
        method: 'get',
        responseType: 'arraybuffer'
      }).then(response => {
        if (response.headers['content-type'] === 'image/png' || response.headers['content-type'] === 'image/jpeg') {
          const arrayBufferView = new Uint8Array(response.data)
          const blob = new Blob([arrayBufferView], { type: response.headers['content-type'] as string })
          const urlCreator = window.URL || window.webkitURL
          const imageUrl = urlCreator.createObjectURL(blob)
          resolve(imageUrl)
        } else {
          reject(new Error(path + ' is not an image'))
        }
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  uploadFile (path: string, file: File, fileName = ''): Promise<AxiosResponse> {
    // for showing progress, consider https://www.bezkoder.com/axios-file-upload/
    return new Promise((resolve, reject) => {
      const fileNameString = fileName ? '?new_file_name=' + fileName : ''
      const formData = new FormData()
      formData.append('file', file)
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/s3/upload/' + process.env.VUE_APP_PROJECT_PATH + path + fileNameString,
        data: formData,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json'
        }
      }).then(response => {
        console.log('File', path, file.name, 'uploaded:', response)
        resolve(response)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  listFiles (path: string): Promise<any> {
    return new Promise((resolve) => {
      return ApiService({
        url: process.env.VUE_APP_API_VERSION + '/s3/listup?path=' + process.env.VUE_APP_PROJECT_PATH + path,
        method: 'get'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        console.error(error)
      })
    })
  },

  deleteFolder (path: string): Promise<any> {
    return new Promise((resolve) => {
      return ApiService({
        url: process.env.VUE_APP_API_VERSION + '/s3/delete/folder/' + process.env.VUE_APP_PROJECT_PATH + path,
        method: 'delete'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        console.log(error)
      })
    })
  },

  deleteFile (file: string): Promise<any> {
    return new Promise((resolve) => {
      return ApiService({
        url: process.env.VUE_APP_API_VERSION + '/s3/delete/file/' + process.env.VUE_APP_PROJECT_PATH + file,
        method: 'delete'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        console.log(error)
      })
    })
  },

  deletePipeline (pipeID: string): Promise<any> {
    return new Promise((resolve) => {
      return ApiService({
        url: process.env.VUE_APP_API_VERSION + '/pipelines/pipeline/delete/' + pipeID,
        method: 'delete'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        console.log(error)
      })
    })
  },

  getGeneTranscripts (geneID: string): Promise<any> {
    return new Promise((resolve) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/transcripts/all_transcript_coords_per_gene/' + geneID,
        method: 'get'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        console.error('Error:', error)
      })
    })
  },

  getTranscriptSeq (transcriptID: string, type: string): Promise<any> {
    return new Promise((resolve) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/transcripts/' + type + '_seq/' + transcriptID,
        method: 'get'
      }).then(response => {
        console.log('Transcript seq:', response.data)
        resolve(response.data)
      }).catch(error => {
        console.error('Error:', error)
      })
    })
  },

  getGeneExpression (geneID: string, category: string): Promise<any> {
    return new Promise((resolve) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/expression/' + category + '/' + geneID,
        method: 'get'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        console.error('Error:', error)
      })
    })
  },

  getGeneInfos (what: string, geneName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let query = ''
      if (what === 'genePassport') {
        query = 'gene_info'
      } else if (what === 'geneSeq') {
        query = 'gene_seq'
      } else if (what === 'proteinSeq') {
        query = 'prot_seq'
      } else if (what === 'cdsSeq') {
        query = 'cds_seq'
      }
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/genes/' + query + '/' + geneName,
        method: 'get'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  getOrthoGroupMembers (orthoGroup: string): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/ortho_groups/genomes/' + orthoGroup,
        method: 'get'
      }).then(response => {
        resolve(response)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  getGeneList (binWidth: number, searchStr: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const subStrSearch = searchStr ? '?sub_str_search=' + searchStr : ''
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/pan_pos/genes/' + binWidth + subStrSearch,
        method: 'get'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  getPanPosOfGene (binWidth: number, geneName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/pan_pos/positions/' + binWidth + '/' + geneName,
        method: 'get'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  getGenomePosOfBin (genome: string, bin: number): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/coords/coords/pos/' + genome + '/' + bin,
        method: 'get'
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  // /pipelines/pipeline/run_clustering
  startPipeline (pipeline: string, paramsFilePath: string): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/pipelines/pipeline/' + pipeline + '?param_file_path=' + process.env.VUE_APP_PROJECT_PATH + paramsFilePath,
        method: 'post'
      }).then(response => {
        resolve(response)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  checkPipelineStatus (pipeline: string): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/pipelines/pipeline/get/' + pipeline,
        method: 'get'
      }).then((response) => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  checkAllPipelineStatus (): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/pipelines/pipeline/get_all',
        method: 'get'
      }).then((response) => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  checkUploadStatus (id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/upload/status/' + id,
        method: 'get'
      }).then((response) => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  uploadExpression (): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/upload/expression',
        method: 'post'
      }).then((response) => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  },

  deleteUploadTask (id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService({
        url: process.env.VUE_APP_API_VERSION + '/upload/task/' + id,
        method: 'delete'
      }).then((response) => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error data:' + error.response.data)
          console.log('Error status:' + error.response.status)
          console.log('Error headers:' + error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:' + error.request)
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error message:' + error.message)
        }
        console.log('Error config:' + error.config)
        reject(error)
      })
    })
  }
}
