import { GeneStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

const namespaced = true

const state: GeneStoreState = {
  newick: '',
  msa: ''
}

const mutations: MutationTree<GeneStoreState> = {
  setNewick (state, newick) {
    state.newick = newick
  },
  setMsa (state, msa) {
    state.msa = msa
  }
}

const actions: ActionTree<GeneStoreState, RootState> = {}
const getters: GetterTree<GeneStoreState, RootState> = {}

export const GeneStore: Module<GeneStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
