import Vue from 'vue'
import { MetaStoreState, RootState } from '@/types/StoreTypes'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import store from '@/store'

const namespaced = true

const state: MetaStoreState = {
  sortingTableGraph: {},
  sortingTableVCF: {},
  blankRowsBeforeReads: 1,
  readsInFiles: false,
  readsetNames: [],
  metaContainerWidth: 0,
  covFraction: 0.05,
  lastCovFraction: 0,
  metaData: {},
  metaDataCategories: [],
  metaTextWidth: 0,
  treeMetadataColumnName: '',
  geneInfo: [],
  homGroupPerGene: {},
  homGroupGenes: {},
  colorLookupTable: {},
  clusteringSortOption: '',
  selectedSortOption: 'ID',
  selectedSortOrder: 'asc',
  linkInfo: {},
  columnInfo: {},
  linkColumns: [],
  maxLinkHeight: 0,
  drawInversions: true,
  drawDuplications: true,
  drawLinks: true,
  selectedLinkType: 'id',
  selectedMetadataToColor: 'none',
  drawCellMargin: false,
  denseView: false,
  tooltipShown: false,
  genomeColorScale: null,
  qtlMetadata: null,
  uploadedMetadata: null
}

const mutations: MutationTree<MetaStoreState> = {
  setUploadedMetadata (state, payload) {
    state.uploadedMetadata = payload
  },

  setSortingTableGraph (state, payload) {
    state.sortingTableGraph = payload
  },

  addSortingTableEntryGraph (state, payload) {
    if (!(payload in state.sortingTableGraph)) {
      Vue.set(state.sortingTableGraph, payload, 0)
    }
  },

  setSortingTableVCF (state, payload) {
    state.sortingTableVCF = payload
  },

  setReadsInFiles (state, payload) {
    state.readsInFiles = payload
  },

  setReadsetNames (state, payload) {
    state.readsetNames = payload
  },

  setMetaContainerWidth (state, payload) {
    state.metaContainerWidth = payload
  },

  setCovFraction (state, payload) {
    state.covFraction = payload
  },

  setLastCovFraction (state, payload) {
    state.lastCovFraction = payload
  },

  setMetaData (state, payload) {
    state.metaData = payload
  },

  addMetaData (state, payload) {
    Vue.set(state.metaData, payload.key, payload.value)
  },

  setMetaDataCategories (state, payload) {
    state.metaDataCategories = payload
  },

  addMetaDataCategory (state, payload) {
    // We make sure the last entry ALWAYS stays the track 'Name'
    // and thus insert the new category at the second-last position
    state.metaDataCategories.splice(state.metaDataCategories.length - 1, 0, payload)
  },

  setTreeMetadataColumnName (state, payload) {
    state.treeMetadataColumnName = payload
  },

  setGeneInfo (state, payload) {
    state.geneInfo = payload
  },

  setHomGroupPerGene (state, payload) {
    state.homGroupPerGene = payload
  },

  setHomGroupGenes (state, payload) {
    state.homGroupGenes = payload
  },

  setColorLookupTable (state, payload) {
    state.colorLookupTable = payload
  },

  setSelectedSortOption (state, payload) {
    state.selectedSortOption = payload
  },

  setSelectedSortOrder (state, payload) {
    state.selectedSortOrder = payload
  },

  setLinkInfo (state, payload) {
    state.linkInfo = payload
  },

  addLinkInfo (state, payload) {
    Vue.set(state.linkInfo, payload.linkId, payload.linkInfo)
  },

  setColumnInfo (state, payload) {
    state.columnInfo = payload
  },

  addColumnInfo (state, payload) {
    Vue.set(state.columnInfo, payload.column, payload.columnInfo)
  },

  setLinkColumns (state, payload) {
    state.linkColumns = payload
  },

  addLinkColumn (state, payload) {
    state.linkColumns.push(payload)
  },

  setMaxLinkHeight (state, payload) {
    state.maxLinkHeight = payload
  },

  setDrawLinks (state, payload) {
    state.drawLinks = payload
  },

  setDrawInversions (state, payload) {
    state.drawInversions = payload
  },

  setDrawDuplications (state, payload) {
    state.drawDuplications = payload
  },

  setSelectedLinkType (state, payload) {
    state.selectedLinkType = payload
  },

  setSelectedMetadataToColor (state, payload) {
    state.selectedMetadataToColor = payload
  },

  setDrawCellMargin (state, payload) {
    state.drawCellMargin = payload
  },

  setDenseView (state, payload) {
    state.denseView = payload
  },

  setTooltipShown (state, payload) {
    state.tooltipShown = payload
  },

  setMetaTextWidth (state, payload) {
    state.metaTextWidth = payload
  },

  setGenomeColorScale (state, payload) {
    state.genomeColorScale = payload
  },

  setQTLMetadata (state, payload) {
    state.qtlMetadata = payload
  }
}

const actions: ActionTree<MetaStoreState, RootState> = {
  setQTLMetadata ({ commit }, payload) {
    commit('setQTLMetadata', payload)
  }
}

const getters: GetterTree<MetaStoreState, RootState> = {
  getAvailableSortOptions: (state) => {
    // Default sort options
    let sortOptions = ['ID', 'Name']

    if (state.metaDataCategories.length > 0) {
      sortOptions = sortOptions.concat(state.metaDataCategories)
    }

    return sortOptions
  },

  metaDataCategories: (state) => {
    const enabledMetaCategories = store.state.pantoStore.enabledMetaCategories
    return state.metaDataCategories.filter((category: string) =>
      enabledMetaCategories.includes(category)
    )
  },

  getLookupTable: (state) => {
    const enabledMetaCategories = store.state.pantoStore.enabledMetaCategories
    const colorLookupTable: any = {}
    enabledMetaCategories.forEach((category: string) => {
      if (state.colorLookupTable[category]) {
        colorLookupTable[category] = state.colorLookupTable[category]
      }
    })
    return colorLookupTable
  }
}

export const MetaStore: Module<MetaStoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
