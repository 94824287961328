import * as PIXI from 'pixi.js'
import store from '@/store'

const PixiConfig = {
  // color definition for bins and background
  // fillColor: 0xABABAB,
  fillColor: 0x696969,
  invColor: 0x8B0000,
  duplColor: 0xade8dc, // 0x5F90B2,
  invDuplColor: 0x725AB2, // 0x524081,
  editColor: 0xDFBF00,
  vcfColor: 0x26d953,
  // emptyColor: 0xF6F6F6,
  emptyColor: 0xFFFFFF,
  exonColor: 0x001C9B,
  intronColor: 0x7F8DCD,
  nextArrowColor: 0x848484,
  tooManyLinksColor: 0x000000,
  arcColor: 0xFF0000,
  markerColor: 0x1AE51A,
  backgroundColor: 0xFFFFFF,
  metaMissingColor: 0xFFFFFF,
  spacerColor: 0x000000,

  // size of the bins in pixel
  cellHeight: 10,
  cellWidth: 10,
  denseCellWidth: 2,
  // size between each bin in pixel
  cellMargin: 1,
  // size of the space between the top and the start of the graph
  topMargin: 10,
  // 400
  bottomMargin: 10,
  // number tracks between graph paths and read tracks
  blankRowsBetweenTrackTypes: 0,
  // ratio for annotation and edit bands from cell height
  upperBandHeightRatio: 0.5,

  // fraction of covered bin to be displayed per path
  covFraction: 0.05,

  // max number of variants to show in tooltips; beyond that show only num variants
  maxNumVariantsToShow: 5,

  // max nr links to be displayed
  maxNrLinks: 200,

  // font size
  // Note: This assumes a binsize of 10 px and a letter spacing of 1 px!
  fontSize: 18.252,
  fontSizeWoMargin: 16.61,

  setDefaults () {
    // The binwidth will be overwritten if the selected default does not exist in the dataset
    store.commit('chunkStore/setBinWidth', 1)
    store.commit('chunkStore/setDataset', null)
    store.commit('chunkStore/setDatasetFolder', '')
    store.commit('metaStore/setCovFraction', 0.05)
    store.commit('chunkStore/setNrChunksToLoad', 1)
  },

  // folder names
  // projectFolderName: 'SOY51/',
  frontendFolderName: 'frontend_data/',
  datasetFolderName: 'frontend_data/datasets/',
  pipelineFolderName: 'pipelines/',
  clusteringFolderName: 'pipelines/clustering/',

  // file name containing gene names and bins per bin size:
  datasetFileName: 'datasets.csv',
  indexFileName: 'index.json',
  metadataFileName: 'metadata.csv',
  qtlFileName: 'qtl.json',
  geneFileName: 'uniq_genes.tsv',
  genoTreeFilename: 'newick.txt',
  homGroupsFileName: 'homGroups.tsv',
  homGroupPerGeneFileName: 'homGroups.tsv',
  homGroupCountSVGPath: 'homGroups/countBarplots/',
  paramsFileName: 'params.yaml',
  uploadPipelinesFileName: 'upload_pipelines.json',

  // bin width above which additional gene information will be hidden in the tooltips
  tooltipInfoSkip: 1000,

  // bin width above which dense view is activated
  denseViewCutoff: 100,

  // chunks to pre-load
  initialNoChunksToLoad: 3,
  nrChunksToLoadEachSide: 0,

  // max nr cached chunks to keep at all times
  maxNrCachedChunks: 12,

  getSequenceBitmapTextStyle () {
    return new PIXI.TextStyle({
      fontFamily: 'monospace',
      fontSize: this.fontSize,
      letterSpacing: 1,
      fill: 0x000000,
      align: 'left'
    })
  },

  getSequenceTextStyle () {
    return new PIXI.TextStyle({
      fontFamily: 'monospace',
      fontSize: this.fontSize,
      letterSpacing: 1,
      fill: 0x000000,
      align: 'left',
      lineHeight: 10
    })
  },

  getGenomeNameTextStyle () {
    return new PIXI.TextStyle({
      fontFamily: '"Lucida Console", Monaco, monospace',
      fontSize: 11,
      letterSpacing: 0,
      fill: 0x000000,
      align: 'left',
      textBaseline: 'alphabetic'
    })
  }
}

export default PixiConfig
