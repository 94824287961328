export default class MSAParser {
  public static parse (msaData: string): { [key: string]: string } {
    const sequences: { [key: string]: string } = {}
    let currentSample = ''
    let currentSequence = ''

    // Split the MSA data into lines
    const lines = msaData.split('\n')

    for (const line of lines) {
      if (line.startsWith('>')) {
        // If we were processing a sequence, save it
        if (currentSample) {
          sequences[currentSample] = currentSequence
        }
        // Start a new sample
        currentSample = line.slice(1).trim()
        currentSequence = ''
      } else {
        // Add to the current sequence, ignoring whitespace
        currentSequence += line.trim()
      }
    }

    // Save the last sequence
    if (currentSample) {
      sequences[currentSample] = currentSequence
    }

    return sequences
  }
}
