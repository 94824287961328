<template>
  <div
    class="tree-window noselect"
    @click="showContextMenu = false"
  >
    <!-- toolbar 0 -->
    <v-toolbar flat color="#eee" class="pt-2">
      <!-- search -->
      <v-text-field
        style="width: 70%"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clear-icon="mdi-close-circle"
        clearable
        @input="renderTree"
        @focus="renderTree"
      ></v-text-field>
    </v-toolbar>
    <!-- toolbar 1 -->
    <v-toolbar flat color="#eee">
      <!-- 'trim tree' switch -->
      <v-switch
        v-model="trimTree"
        class="switch"
        :label="'Trim on search'"
        @change="renderTree"
      ></v-switch>
      <!-- 'align leaves' switch -->
      <v-switch
        v-model="variableBranchLengths"
        class="switch"
        :label="'Variable branch lengths'"
        @change="renderTree"
        @focus="renderTree"
      ></v-switch>
      <!-- meta menu -->
      <!-- <v-menu
        offset-y
        :close-on-content-click="false"
        max-height="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            v-bind="attrs"
            v-on="on"
          >
            Metadata <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <ul class="metacat-list">
          <li v-for="(category, index) in filteredMetaCategories" :key="index">
            <v-switch
              class="switch"
              :label="category"
              :input-value="isMetaCategoryEnabled(category)"
              @change="toggleCategory(category)"
            ></v-switch>
          </li>
        </ul>
      </v-menu> -->
    </v-toolbar>
    <!-- tree -->
    <div
      class="tree-container"
      ref="treeContainer"
      @contextmenu.prevent="openContextMenu"
    ></div>
    <!-- Context Menu -->
    <div v-if="showContextMenu" :style="{ top: `${contextMenuY}px`, left: `${contextMenuX}px` }" class="context-menu">
      <v-list dense flat>
        <v-list-item :disabled="!isSelected || subtrees.length == maxSubtrees" @click="newSubtree">
          <v-list-item-title>
            <span :class="{ 'crossed-out': subtrees.length == maxSubtrees }">New subtree</span>
            {{ maxSubtreesReached }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :disabled="subtreeIndex == 0" @click="plotClusters">
          <v-list-item-title>Plot subtrees ({{subtreeIndex}})</v-list-item-title>
        </v-list-item>
        <v-list-item :disabled="subtreeIndex == 0" @click="clearAllSubtrees">
          <v-list-item-title>Clear all subtrees</v-list-item-title>
        </v-list-item>
        <v-list-item @click="centerTree">
          <v-list-item-title>Center Tree</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
    <!-- samples browser -->
    <!-- <div class="samples-browser">
      <span>Found <strong>{{ matchingSamples.length }}</strong> {{ matchingSamples.length <= 1 ? 'matching sample' : 'matching samples' }}</span>
      <v-btn
        class="btn-browse-samples"
        @click="browseSamples"
        outlined
      >
        Browse
      </v-btn>
    </div> -->
    <!-- multi-selection hint -->
    <!-- <div class="multi-selection-hint">
      <v-icon left small color="#b1b1b1">mdi-information</v-icon>
      <span>Press 'a + click' for multi-selection</span>
    </div> -->
    <!-- tooltip -->
    <!-- <Tooltip :tooltipText="tooltipText" :showTooltip="showTooltip">
      <div class="tooltip-content">
        {{ tooltipText }}
      </div>
    </Tooltip> -->
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'
import * as d3 from 'd3'
import NewickParser from '@/utils/NewickParser'
import { ApiQueryService } from '@/services/ApiQueryService'
import Config from '@/graph/Config'
import Tooltip from '@/components/Tooltip.vue'
import { Clusters } from '@/types/Types'
import ColorUtils from '@/utils/ColorUtils'
import MSAParser from '@/utils/MSAParser'
import GeneralUtils from '@/utils/GeneralUtils'

@Component({
  components: {
    Tooltip
  }
})
export default class GeneTree extends Vue {
  data: any | null = null
  root: any | null = null
  nodes: any[] = []
  links: any[] = []
  treeList: { label: string, file: string, metadata: string | null }[] = []
  selectedTree = ''
  selectedNodes: any[] = []
  selectedLeaves: any[] = []
  matchingSamples: string[] = []
  isSelected = false
  isMultiselectionEnabled = false
  sequences: { [key: string]: string } = {}
  copiedSequence = ''

  // ------------------------------------------------
  // Tree
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> | null = null
  treeGroup: d3.Selection<SVGGElement, unknown, null, undefined> | null = null
  nodeGroup: d3.Selection<SVGGElement, any, SVGGElement, unknown> | undefined = undefined
  linkGroup: d3.Selection<SVGPathElement, any, SVGGElement, unknown> | undefined = undefined
  scale = 0.2
  translation = { x: 0, y: 300 }
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown> | null = null
  maxBranchLen = 500
  branchLenFactor = 1

  // Subtrees / Clusters
  clusters: Clusters = {} // subtrees' leaves
  subtrees: any[] = []
  subtreeIndex = 0
  maxSubtrees = 5
  subtreeColorScale = ColorUtils.getColorScale(this.maxSubtrees, true)
  colorScaleIndex = 0
  plotColors: Record<string, string> = {}
  clusterNames: string[] = []

  // ------------------------------------------------
  // Toolbar
  search = ''
  trimTree = false
  variableBranchLengths = false

  // ------------------------------------------------
  // Tooltip
  tooltipText = ''
  showTooltip = false

  // ------------------------------------------------
  // Context Menu
  showContextMenu = false
  contextMenuX = 0
  contextMenuY = 0

  // ------------------------------------------------
  // GETTERS AND SETTERS
  // ------------------------------------------------

  get dataset () {
    return this.$store.state.chunkStore.dataset
  }

  // Tree state

  get treeState () {
    return this.$store.state.pantoStore.treeState
  }

  set treeState ({ enabled }) {
    this.$store.commit('pantoStore/setTreeState', {
      enabled
    })
  }

  get metadata () {
    return this.$store.state.metaStore.metaData
  }

  get lookupTable () {
    return this.$store.getters['metaStore/getLookupTable']
  }

  get filteredMetaCategories () {
    return this.metaDataCategories.filter((category: string) => category !== 'Name')
  }

  get metaDataCategories () {
    return this.$store.state.metaStore.metaDataCategories
  }

  get enabledMetaCategories () {
    return this.$store.state.pantoStore.enabledMetaCategories
  }

  set enabledMetaCategories (value: string[]) {
    this.$store.commit('pantoStore/setEnabledMetaCategories', value)
  }

  get allMetaCategoriesEnabled () {
    return this.$store.state.pantoStore.allMetaCategoriesEnabled
  }

  set allMetaCategoriesEnabled (value: boolean) {
    this.$store.commit('pantoStore/setAllMetaCategoriesEnabled', value)
  }

  get maxSubtreesReached () {
    return this.subtrees.length === this.maxSubtrees ? ' (max number of subtrees reached)' : ''
  }

  // Getter only
  get isMetaCategoryEnabled () {
    return this.$store.getters['pantoStore/isMetaCategoryEnabled']
  }

  // Getter only
  get newick () {
    return this.$store.state.geneStore.newick
  }

  get msa () {
    return this.$store.state.geneStore.msa
  }

  // ------------------------------------------------
  // WATCHERS
  // ------------------------------------------------

  @Watch('msa')
  onMsaChange () {
    this.prepTree()
    this.renderTree()
    this.centerTree()
  }

  // ------------------------------------------------
  // METHODS
  // ------------------------------------------------

  mounted () {
    // Create svg
    this.svg = d3.select(this.$refs.treeContainer as HTMLElement)
      .append('svg')
      .attr('width', '100%')
      .attr('height', 800)

    // Create a group for the tree that will be transformed by zoom
    this.treeGroup = this.svg?.append('g') ?? null
    // this.treeGroup?.style('transform', `translate(${this.translation.x}px, ${this.translation.y}px) scale(${this.scale})`)

    // ------------------------------------------------
    // ZOOM
    // ------------------------------------------------

    // Create zoom
    this.zoom = d3.zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.01, 4])
      .on('zoom', (event) => {
        this.scale = event.transform.k
        this.translation = { x: event.transform.x, y: event.transform.y }
        this.treeGroup?.style('transform', `translate(${event.transform.x}px, ${event.transform.y}px) scale(${event.transform.k})`)
      })

    // Apply zoom
    this.svg?.call(this.zoom)
    this.svg?.call(this.zoom.transform, d3.zoomIdentity.translate(this.translation.x, this.translation.y).scale(this.scale))

    // ------------------------------------------------
    // MULTI-SELECTION KEY LISTENERS
    // ------------------------------------------------
    document.addEventListener('keydown', (e) => {
      if (e.key === 'a') {
        this.isMultiselectionEnabled = true
      }
    })
    document.addEventListener('keyup', (e) => {
      if (e.key === 'a') {
        this.isMultiselectionEnabled = false
      }
    })
  }

  close () {
    this.$store.commit('pantoStore/setTreeToShow', '')
    this.treeState = { enabled: false }
  }

  getTrees (): Promise<void> {
    return new Promise((resolve) => {
      let treeToSelect: { label: string, file: string, metadata: string | null } | null = null
      this.treeList = []
      const treeFolder = Config.datasetFolderName + 'trees/'
      ApiQueryService.listFiles(treeFolder)
        .then((response) => {
          response.files.forEach((file: string) => {
            // ignore metadata files of trees (they contain 'metadata' in the file name)
            if (file.indexOf('metadata') === -1) {
              const idx = file.indexOf(this.dataset)
              if (idx !== -1) {
                treeToSelect = { label: this.dataset + '-wide tree', file: treeFolder + file, metadata: treeFolder + file.replace('.dnd', '.metadata.tsv') }
                this.treeList.push(treeToSelect)
              } else if (file.includes(Config.genoTreeFilename)) {
                this.treeList.push({ label: 'Tree of genotyped samples', file: treeFolder + file, metadata: null })
              }
            }
          })

          ApiQueryService.listFiles(Config.clusteringFolderName)
            .then((response) => {
              if (response.directories.length > 0) {
                const promises: Promise<void>[] = []
                // Scan folders of clustering pipeline data
                response.directories.forEach((folder: string) => {
                  folder = folder.slice(0, -1)
                  const treeFilePath = Config.clusteringFolderName + folder + '/output/' + folder + '.tree.dnd'
                  // Check if the folder contains a tree file in the output/ subdirectory
                  promises.push(ApiQueryService.listFiles(Config.clusteringFolderName + folder + '/output')
                    .then((response) => {
                      if (response.files.includes(folder + '.tree.dnd')) {
                        const treeObj = { label: 'Cluster ' + folder, file: treeFilePath, metadata: treeFilePath.replace('.tree.dnd', '-tree.metadata.tsv') }
                        this.treeList.push(treeObj)
                        if (treeFilePath === this.$store.state.pantoStore.treeToShow) {
                          treeToSelect = treeObj
                          // If tree clustering should be shown, then load also its metadata
                          // promises.push(DataProvider.readTreeMetadataFile(Config.clusteringFolderName + folder + '/output/' + folder + '-tree.metadata.tsv'))
                        }
                      }
                    })
                    .catch((error) => {
                      console.error('listUp failed' + error)
                    }))
                })
                Promise.all(promises).then(() => {
                  if (!treeToSelect && this.treeList.length > 0) {
                    treeToSelect = this.treeList[0] // treeFolder + this.treeList[0].file
                  }
                  if (treeToSelect) {
                    this.selectedTree = treeToSelect.file
                  }
                  resolve()
                })
              }
            })
            .catch((error) => {
              console.error('listUp failed' + error)
            })
        })
        .catch((error) => {
          console.error('listUp failed' + error)
        })
    })
  }

  prepTree (): void {
    if (!this.newick || !this.msa) {
      return
    }

    // Parse newick
    this.data = NewickParser.parse(this.newick).json

    // Parse MSA
    this.sequences = MSAParser.parse(this.msa)

    // Create root node
    this.root = d3.hierarchy(this.data)

    // Create nodes and links
    this.nodes = this.root.descendants()
    this.links = this.root.links()

    // Create tree layout
    const treeLayout = d3.cluster()
      .nodeSize([20, 20])
    treeLayout(this.root)
  }

  parseSearchInput (searchInput: string): string[] {
    if (!searchInput.trim()) {
      // If the input is empty or only contains whitespace, return an empty array
      return []
    }
    // Assuming the format is "sample1, sample2, ..."
    const sampleNames = searchInput.split(',').map(name => name.trim())
    return sampleNames
  }

  renderTree () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    // ------------------------------------------------
    // RESET
    // ------------------------------------------------
    this.treeGroup?.selectAll('*').remove()

    // Reset selection
    this.resetSelection()

    // ------------------------------------------------
    // FILTERING
    // ------------------------------------------------

    // Parse search input to get an array of sample names
    const sampleNames = this.parseSearchInput(this.search)

    let filteredLeaves
    if (sampleNames.length > 0) {
      // Filter leaves based on the sample names
      filteredLeaves = this.root.leaves().filter((leaf: any) => {
        return sampleNames.some(sampleName => leaf.data.name.includes(sampleName))
      })
    } else {
      // If there are no sample names, skip filtering
      filteredLeaves = this.root.leaves()
    }

    // Filter nodes (by getting the ancestors of the pre-filtered leaves)
    const filteredNodes = filteredLeaves.flatMap((leaf: any) => leaf.ancestors())
      .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index)

    // Get branch length range and calculate factor for drawing branch lengths
    const branchLengths = this.nodes.map((node: any) => node.data.attribute)
    const maxTreeBranchLen = branchLengths.filter((branchLen: any) => !isNaN(Number(branchLen))).reduce((a: any, b: any) => Math.max(a, b))
    const minTreeBranchLen = branchLengths.filter((branchLen: any) => !isNaN(Number(branchLen))).reduce((a: any, b: any) => Math.min(a, b))
    this.branchLenFactor = (maxTreeBranchLen / minTreeBranchLen > 2000 ? 2000 : this.maxBranchLen) / maxTreeBranchLen
    // console.log('branchLenFactor', this.branchLenFactor, minTreeBranchLen, maxTreeBranchLen, maxTreeBranchLen / minTreeBranchLen)

    // Store y positions of the nodes
    const nyPositions = new Map()
    this.nodes.forEach((node: any) => {
      let nyPosition = 0
      let currentNode = node
      while (currentNode) {
        nyPosition += this.getBranchLength(currentNode)
        currentNode = currentNode.parent
      }
      nyPositions.set(node, nyPosition)
    })

    // ------------------------------------------------
    // LINKS
    // ------------------------------------------------

    let linkData = this.treeGroup?.selectAll('.link')
      .data(this.links)
      .enter()

    if (this.trimTree) {
      linkData = linkData?.filter((d: any) => {
        return filteredNodes.includes(d.source) && filteredNodes.includes(d.target)
      })
    }

    this.linkGroup = linkData?.append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', '#444')
      .attr('stroke-width', 1.5)
      .attr('d', (d: any) => {
        let sourceY = d.source.y
        let targetY = d.target.y
        if (this.variableBranchLengths) {
          sourceY = nyPositions.get(d.source)
          targetY = nyPositions.get(d.target)
        }
        return `M${sourceY},${d.source.x}
                L${sourceY},${d.target.x}
                L${targetY},${d.target.x}`
      })

    // ------------------------------------------------
    // NODES
    // ------------------------------------------------

    let nodeData = this.treeGroup?.selectAll('.node')
      .data(this.nodes)
      .enter()

    if (this.trimTree) {
      nodeData = nodeData?.filter((d: any) => {
        return filteredNodes.includes(d)
      })
    }

    this.nodeGroup = nodeData?.append('g')
      .attr('class', 'node')
      .attr('transform', (d: any) => {
        if (this.variableBranchLengths) {
          return `translate(${nyPositions.get(d)},${d.x})`
        } else {
          return `translate(${d.y},${d.x})`
        }
      })
      .style('cursor', 'pointer')

    // Draw circles
    this.nodeGroup?.append('circle')
      .attr('r', (d: any) => this.isNotLeaf(d) ? 4 : 3)
      .style('fill', (d: any) => this.hasLowBootstrap(d.data.name) ? '#F77043' : '#444')

    // Draw sample names
    this.nodeGroup?.each(function (d: any) {
      if (!that.isNotLeaf(d)) {
        d3.select(this).append('text')
          .text(d.data.name)
          .attr('x', 4)
          .attr('y', 4)
          .style('font-size', '12px')
          .style('fill', '#444')
      }
    })

    // ------------------------------------------------
    // DRAW SEQUENCES
    // ------------------------------------------------

    let maxTextWidth = 0
    setTimeout(() => {
      this.nodeGroup?.each(function () {
        // Get sample name's width
        const textElement = d3.select(this).select('text').node() as SVGTextElement
        const textWidth = textElement?.getBBox().width
        // Update maxTextWidth
        if (maxTextWidth < textWidth) {
          maxTextWidth = textWidth
        }
      })
    }, 0)

    this.nodeGroup?.each(function (d: any) {
      if (!that.isNotLeaf(d)) {
        // Set timeout to wait for the svg to be created (so we can access the text element's width)
        setTimeout(() => {
          const textElement = d3.select(this).select('text').node() as SVGTextElement
          const textWidth = textElement?.getBBox().width

          // Get the sequence
          const sequence = that.sequences[d.data.name] || ''

          // Draw the sequence
          d3.select(this).append('text')
            .attr('class', 'sequence-text')
            .attr('x', (!that.variableBranchLengths ? maxTextWidth : textWidth) + 20)
            .attr('y', 4)
            .style('font-size', '10px')
            .style('font-family', 'monospace')
            .style('fill', '#444')
            .text(sequence)
        }, 0)
      }
    })

    // -------------------------------------------------
    // Select nodes/leaves automatically on search input
    // -------------------------------------------------

    if (this.search.trim()) { // if the input is not empty or not only contains whitespace
      // Select nodes and leaves
      this.isSelected = true
      this.selectedNodes = filteredNodes
      this.selectedLeaves = filteredLeaves

      // Highlight selected nodes
      this.highlightSubtree('#2D7FFA')

      // Select VCFs
      this.selectVCFTracks()
    }

    // ------------------------------------------------
    // INTERACTIONS
    // ------------------------------------------------

    this.nodeGroup?.on('mouseover', (e, node: any) => {
      // Highlight current node
      const color = node.data.color ? node.data.color : '#2D7FFA'
      d3.select(e.currentTarget).select('circle').style('fill', color)
      d3.select(e.currentTarget).selectAll('text').style('fill', color)

      // Show tooltip
      this.tooltipText = node.data.name
      if (this.tooltipText.length > 0) {
        this.showTooltip = true
      }
    })

    this.nodeGroup?.on('mouseout', (e, node: any) => {
      if (!this.isSelected || !this.selectedNodes.includes(node)) {
        const color = node.data.color ? node.data.color : (this.hasLowBootstrap(node.data.name) ? '#F77043' : '#444')
        d3.select(e.currentTarget).select('circle').style('fill', color)
        d3.select(e.currentTarget).selectAll('text').style('fill', '#444')
      } else {
        // If a selection has been made, keep the highlight color as '#2D7FFA'
        d3.select(e.currentTarget).select('circle').style('fill', '#2D7FFA')
        d3.select(e.currentTarget).selectAll('text').style('fill', '#2D7FFA')
      }
      this.showTooltip = false
    })

    this.nodeGroup?.on('click', (e, node: any) => {
      if (!this.isNotLeaf(node)) {
        const sequence = this.sequences[node.data.name] || ''
        GeneralUtils.copyToClipboard(sequence)
        this.copiedSequence = node.data.name
        setTimeout(() => {
          this.copiedSequence = ''
        }, 2000)
      }
      this.selectSubtree(node)
    })

    // Click anywhere outside the nodes (circle / text) to reset highlighting
    this.svg?.on('click', (e) => {
      if (e.target.nodeName !== 'circle' && e.target.nodeName !== 'text') {
        this.resetHighlighting()
        this.resetSelection()
      }
    })

    // ------------------------------------------------

    // If the leaves are aligned, we translate the tree
    // so the leaves appear at the center of the window
    if (!this.variableBranchLengths) {
      const svgWidth = this.svg?.node()?.getBoundingClientRect().width
      const center = ((svgWidth ?? 0) / 2) - (this.treeGroup?.node()?.getBoundingClientRect().width ?? 0)
      this.treeGroup?.style('transform', `translate(${center}px, ${this.translation.y}px) scale(${this.scale})`)
      if (this.zoom) {
        this.svg?.call(this.zoom.transform, d3.zoomIdentity.translate(center, this.translation.y).scale(this.scale))
      }
    }

    // ------------------------------------------------
    this.highlightSubtrees()
  }

  highlightSubtrees () {
    this.subtrees.forEach((subtree) => {
      subtree.forEach((node: any) => {
        const color = node.data.color
        this.nodeGroup?.selectAll('circle').filter((d: any) => d === node)
          .style('fill', color)
        this.nodeGroup?.selectAll('text').filter((d: any) => d === node)
          .style('fill', color)
        this.linkGroup?.filter((link: any) => subtree.includes(link.source))
          .style('stroke', color)
      })
    })
  }

  highlightSubtree (color: string) {
    this.resetHighlighting()

    // Highlight the clicked node and its descendants
    this.nodeGroup?.filter((node: any) => this.selectedNodes.includes(node))
      .each(function () {
        d3.select(this).select('circle')
          .style('fill', color)
        d3.select(this).selectAll('text').style('fill', color)
      })

    // Highlight the links to the descendants
    this.linkGroup?.filter((link: any) => this.selectedNodes.includes(link.source))
      .style('stroke', color)
  }

  selectSubtree (node: any) {
    const descendants = node.descendants()
    const leaves = node.leaves()

    // Multiselection / single selection
    if (this.isMultiselectionEnabled && this.isSelected) {
      descendants.forEach((node: any) => {
        if (!this.selectedNodes.includes(node)) {
          this.selectedNodes.push(node)
        }
      })
      leaves.forEach((leaf: any) => {
        if (!this.selectedLeaves.includes(leaf)) {
          this.selectedLeaves.push(leaf)
        }
      })
    } else {
      this.selectedNodes = descendants
      this.selectedLeaves = leaves
    }

    this.isSelected = true
    this.selectVCFTracks()

    this.highlightSubtree('#2D7FFA')
  }

  getBranchLength (d: any): number {
    if (isNaN(Number(d.data.attribute))) {
      return 0
    } else {
      return Number(d.data.attribute) * this.branchLenFactor
    }
  }

  convertOxColor (oxColor: string) {
    return '#' + oxColor.slice(2)
  }

  isNotLeaf (node: any) {
    return node.children
  }

  hasLowBootstrap (nodeName: string) {
    if (nodeName.includes('/')) {
      const split = nodeName.split('/')
      const bootstrapRatio = Number(split[0]) / Number(split[1])
      if (bootstrapRatio < 0.9) {
        return true
      }
    } else {
      return false
    }
  }

  resetHighlighting () {
    this.nodeGroup?.selectAll('circle').style('fill', (d: any) => {
      if (d.data.color) {
        return d.data.color
      } else if (this.hasLowBootstrap(d.data.name)) {
        return '#F77043'
      } else {
        return '#444'
      }
    })
    this.nodeGroup?.selectAll('text').style('fill', (d: any) => d.data.color ? d.data.color : '#444')
    d3.selectAll('path.link').style('stroke', (d: any) => d.source && d.source.linkColor ? d.source.linkColor : '#444')
  }

  resetSelection () {
    this.isSelected = false
    // Set selected nodes to 'all' by default
    this.selectedNodes = this.nodes
    this.selectedLeaves = this.root.leaves()
    // Select VCFs
    this.selectVCFTracks()
  }

  selectVCFTracks () {
    // Select VCF tracks that are in the selected nodes
    this.matchingSamples = []
    this.$store.state.chunkStore.vcfTracks.forEach((track: any) => {
      if (this.selectedLeaves.map((node: any) => node.data.name).includes(track.label)) {
        this.matchingSamples.push(track.label)
      }
    })
  }

  centerTree () {
    if (this.svg && this.treeGroup) {
      const svgBounds = this.svg.node()?.getBoundingClientRect()
      const treeBounds = this.treeGroup.node()?.getBoundingClientRect()

      if (svgBounds && treeBounds) {
        const scale = Math.min(
          (svgBounds.width * 0.9) / treeBounds.width,
          (svgBounds.height * 0.9) / treeBounds.height
        )

        const translateX = (svgBounds.width - treeBounds.width * scale) / 2
        const translateY = (svgBounds.height - treeBounds.height * scale) / 2

        this.scale = scale
        this.translation = { x: translateX, y: translateY }

        if (this.zoom) {
          this.svg.transition()
            .duration(750)
            .call(this.zoom.transform, d3.zoomIdentity.translate(translateX, translateY).scale(scale))
        }
      }
    }

    this.showContextMenu = false
  }

  // Close the tree and show the vcf tracks matching the selected nodes
  browseSamples () {
    this.$store.commit('pantoStore/setEnabledVCFTracks', this.matchingSamples)
    this.$store.commit('pantoStore/setTreeState', { enabled: false })
  }

  // ------------------------------------------------
  // META MENU (shared state with MetaMenu.vue)
  // ------------------------------------------------
  toggleAllCategories () {
    this.$store.dispatch('pantoStore/toggleAllCategories')
  }

  toggleCategory (category: string) {
    this.$store.dispatch('pantoStore/toggleCategory', category)
  }

  // ------------------------------------------------

  openContextMenu (e: MouseEvent) {
    // this.contextMenuX = e.clientX
    // this.contextMenuY = e.clientY
    // this.showContextMenu = true
  }

  // ------------------------------------------------

  clearAllSubtrees () {
    // Iterate over all subtrees
    this.subtrees.forEach(subtree => {
      // Iterate over all nodes in the subtree
      subtree.forEach((node: any) => {
        // Remove the color property from the node
        delete node.data.color
      })

      // Reset the color of the links
      this.linkGroup?.filter((link: any) => subtree.includes(link.source))
        .each(function (link: any) {
          // Remove the linkColor property from the link source
          delete link.source.linkColor
        })
    })

    // Reset the subtrees array
    this.subtrees = []

    // Reset the subtree index
    this.subtreeIndex = 0

    // Call resetHighlighting to apply the default colors
    this.resetHighlighting()

    // Reset the selected nodes
    this.resetSelection()
  }

  newSubtree () {
    // Check if the selected nodes form an existing subtree
    const isExistingSubtree = this.subtrees.some(subtree => {
      const existingSubtreeNamesSet = new Set(subtree.map((node: any) => node.data.name))
      return this.selectedNodes.length === subtree.length && this.selectedNodes.every(node => existingSubtreeNamesSet.has(node.data.name))
    })
    if (isExistingSubtree) {
      alert('This subtree already exists.')
      return // Exit the function if the subtree already exists
    }

    // Assign a new name to the subtree
    const newName = window.prompt('Name your new subtree', 'Subtree ' + (this.subtreeIndex + 1))
    if (!newName || newName.trim() === '') {
      console.log('No new name provided for the subtree.')
      return // Exit if no valid name is provided
    }
    this.clusterNames[this.subtreeIndex] = newName.trim()

    // Convert this.selectedNodes to a Set
    const selectedNodesSet = new Set(this.selectedNodes)

    // Convert selectedLeafNames to a Set
    const selectedLeafNamesSet = new Set(this.selectedLeaves.map((leaf: any) => leaf.data.name))

    for (let i = 0; i < this.subtrees.length; i++) {
      const subtree = this.subtrees[i]
      const cluster = this.clusters[this.clusterNames[i]]
      // If the new subtree is a subset of the existing subtree
      // or the new selection of leaves is a subset of the existing cluster
      if ((subtree && subtree.every((node: any) => selectedNodesSet.has(node))) ||
          (cluster && cluster.every((leaf: string) => selectedLeafNamesSet.has(leaf)))) {
        this.subtrees.splice(i, 1) // Remove the subtree
        delete this.clusters[this.clusterNames[i]] // Remove the cluster
        this.clusterNames.splice(i, 1) // Remove the cluster name
        i-- // Adjust the index after removing an item from the array
        this.subtreeIndex-- // Decrement the subtreeIndex to reuse the color
      }
    }

    // Update the keys of the remaining clusters
    const updatedClusters: { [key: string]: any } = {}
    Object.keys(this.clusters).forEach((key, index) => {
      updatedClusters[this.clusterNames[index]] = this.clusters[key]
    })
    this.clusters = updatedClusters

    // ----------------------------------------------

    // Set the color of the selected nodes
    const color = this.subtreeColorScale[this.colorScaleIndex]
    // Increment and loop the color index
    this.colorScaleIndex = (this.colorScaleIndex + 1) % this.subtreeColorScale.length

    this.selectedNodes.forEach(function (node) {
      node.data.color = color
    })
    this.linkGroup?.filter((link: any) => this.selectedNodes.includes(link.source))
      .each(function (link: any) {
        link.source.linkColor = color
      })

    // Highlight the new subtree
    this.highlightSubtree(color)

    // ----------------------------------------------

    // Add the new subtree to the list of subtrees
    this.subtrees.push(this.selectedNodes)

    // Add the subtree to the clusters
    this.clusters[this.clusterNames[this.subtreeIndex]] = Array.from(selectedLeafNamesSet)

    // Add the plot color
    this.plotColors[this.clusterNames[this.subtreeIndex]] = color

    // Increment the subtree index
    this.subtreeIndex++

    // Reset the selected nodes
    this.resetSelection()
  }

  plotClusters () {
    this.$store.commit('pantoStore/setClusterPlotState', { enabled: true, clusters: this.clusters, plotColors: this.plotColors })
  }

  resize (size: { width: number, height: number }) {
    this.svg?.attr('width', size.width)
      .attr('height', size.height)
  }
}

</script>

<style lang="scss" scoped>
.tree-window {
  position: relative;
}

.tree-container {
  width: 100%;
}

.samples-browser {
  position: absolute;
  left: 20px;
  bottom: 20px;
  border-radius: $border-radius;
  padding: 8px;
  font-size: 14px;
  background-color: #c0c0c03e;
  backdrop-filter: blur(10px);

  strong {
    color: $primary-blue;
  }
}

.btn-browse-samples {
  margin-left: 10px;
}

.switch {
  margin: 0 20px 0 0;
  padding-top: 20px;
}

.metacat-list {
  .switch {
    margin: 0 20px;
  }
}

.v-application ul {
  padding: 0;
  margin: 0;
}

.v-toolbar__content {
  padding: 0 !important;
}

.v-menu__content {
  background-color: white !important;
}

.multi-selection-hint {
  position: absolute;
  top: 138px;
  right: 20px;
  font-size: 14px;
  color: #7f7f7f;
  display: flex;
  align-items: center;
}
</style>
